import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "introduction-view" }
const _hoisted_2 = { class: "intro-container" }
const _hoisted_3 = { key: 1 }

import MdViewer from "@/components/MdViewer.vue";
import generalIntro from "/public/Introduction/General.md?"; // 使用 import 引入 md
import acmIntro from "/public/Introduction/ACM.md?"; // 使用 import 引入 md
import ctfIntro from "/public/Introduction/CTF.md?"; // 使用 import 引入 md
import { onMounted, onUnmounted, ref } from "vue"; // 使用 import 引入 md

export default /*@__PURE__*/_defineComponent({
  __name: 'IntroductionView',
  setup(__props) {

const isBoarded = ref(false);
// 是否为窄屏，窄屏单列排布
const isSmallWidth = ref(false);
const getWidthAndHeight = () => {
  // 显示宽高比小于1.5或屏幕宽高比小于1.5，判定为窄屏
  isSmallWidth.value =
    window.innerWidth / window.innerHeight < 1.5 ||
    window.screen.width / window.screen.height < 1.5;
};
onMounted(() => {
  window.addEventListener("resize", getWidthAndHeight);
  getWidthAndHeight();
});
onUnmounted(() => {
  window.removeEventListener("resize", getWidthAndHeight);
});

return (_ctx: any,_cache: any) => {
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_card, { bordered: isBoarded.value }, {
        default: _withCtx(() => [
          _createVNode(MdViewer, { value: _unref(generalIntro) }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["bordered"]),
      _createVNode(_component_a_divider, {
        size: 2,
        style: {"border-bottom-style":"dotted"}
      }),
      (!isSmallWidth.value)
        ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_card, {
                    class: "self-intro",
                    bordered: isBoarded.value
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MdViewer, { value: _unref(acmIntro) }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["bordered"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_card, {
                    class: "self-intro",
                    bordered: isBoarded.value
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MdViewer, { value: _unref(ctfIntro) }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["bordered"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_a_card, {
              class: "self-intro",
              bordered: isBoarded.value
            }, {
              default: _withCtx(() => [
                _createVNode(MdViewer, { value: _unref(acmIntro) }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["bordered"]),
            _createVNode(_component_a_divider, {
              size: 2,
              style: {"border-bottom-style":"dotted"}
            }),
            _createVNode(_component_a_card, {
              class: "self-intro",
              bordered: isBoarded.value
            }, {
              default: _withCtx(() => [
                _createVNode(MdViewer, { value: _unref(ctfIntro) }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["bordered"])
          ]))
    ])
  ]))
}
}

})