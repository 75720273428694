import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import math from "@bytemd/plugin-math";
import { Viewer } from "@bytemd/vue-next";


/**
 定义组件属性类型
 */
interface Props {
  value: string;
}

/**
 给组件指定初始值
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'MdViewer',
  props: {
    value: { default: () => "" }
  },
  setup(__props: any) {

const props = __props;

const plugins = [
  gfm(),
  highlight(),
  math(),
  // Add more plugins here
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Viewer), {
    plugins: plugins,
    value: props.value
  }, null, 8, ["value"]))
}
}

})