import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "basicLayout" }
const _hoisted_2 = { class: "layout-container" }

import GlobalHeader from "@/components/GlobalHeader.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'BasicLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout, { style: {"min-height":"100vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_header, { class: "layout-header" }, {
          default: _withCtx(() => [
            _createVNode(GlobalHeader)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_a_layout_footer, { class: "layout-footer" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("*C* Team")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})